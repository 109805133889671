<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Calculate the buffer capacity on the initial gradual change of the pH curve (<b
          >between 2 to 7 mL of added titrant</b
        >) for a weak acid buffer titrated with
        <stemble-latex content="$\text{0.1 M NaOH}$" />
        (Titration curve and data table below). Buffer capacity does not have units. Report the
        answer with two significant figures.
      </p>
      <img
        src="/img/assignments/ChemUCI1LC_Q4S1_q8_img.png"
        alt="Image of a weak acid buffer titration with 0.1 M NaOH"
        style="max-height: 300px"
      />

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\text{Buffer Capacity: }$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemUCI1LC_Q4S1_Q8',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
};
</script>
